// routes
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Survey',
    items: [
      {
        title: 'Create Survey',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban,
      },
      { title: 'View Survey', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
     
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'Event Types',
    items: [
      { title: 'View Event Types', path: PATH_DASHBOARD.general.container, icon: ICONS.booking },
    ],
  },
];

export default navConfig;
