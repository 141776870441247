import React, { useEffect, useState } from 'react';
import { Button, IconButton, Typography, Box } from '@mui/material';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import { Survezy } from 'survezy_widget';
import 'survezy_widget/dist/index.css';
import { useTheme } from '@emotion/react';


const PreviewScreen = () => {
  const getSurvey = () => {
    const survey = JSON.parse(localStorage.getItem('survey'))

    if (!survey) return { questions: [] }

    return {
      questions: survey.questions.map((question) => {
        return {
          ...question,
          options: question.options.map((option) => option.value),
        };
      }),
    };
  };

  const [isDesktop, setIsDesktop] = useState(true);
  const [survey, setSurvey] = useState(getSurvey());

  if (!survey) return null

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#808080',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <Typography style={{ marginTop: '5px', color: 'white' }}>Switch to </Typography>
        <IconButton onClick={() => setIsDesktop(true)}>
          <DesktopMacIcon style={{ marginLeft: '10px', marginRight: '10px', color: 'white' }} />
        </IconButton>
        <IconButton onClick={() => setIsDesktop(false)}>
          <PhoneAndroidIcon style={{ color: 'white' }} />
        </IconButton>

        <Button variant='contained' color="secondary" onClick={() => setSurvey(getSurvey())}>
          Refresh Survey
        </Button>
      </div>
      {isDesktop ? <DesktopScreenPreview demoSurvey={survey} /> : <MobileScreenPreview demoSurvey={survey} />}
    </div>
  );
};

const MobileScreenPreview = ({ demoSurvey }) => {
  const theme = useTheme();
  const myStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '340px',
    height: '620px',
    backgroundColor: '#fff',
    borderRadius: '45px',
    border: '0.5px solid white',
    marginTop: '20px',
  };
  return (
    <Box
      style={myStyle}
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '10px',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-end',
          paddingRight: '20px',
        }}
      >
        <div style={{ alignSelf: 'flex-start' }}>
          <SignalCellularAltIcon />
        </div>
      </div>

      {demoSurvey.questions.length !== 0 &&
        <Survezy
          demoSurvey={demoSurvey}
          sx={{
            position: 'absolute',
            // margin: 'auto',
            width: '290px',

          }}
        />}
    </Box>
  );
};

const DesktopScreenPreview = ({ demoSurvey }) => {
  const myStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
    border: '0.5px solid white',
    marginTop: '20px',
  };
  return (
    <div style={myStyle} sx={{ boxShadow: 3 }}>
      <div
        style={{
          position: 'absolute',
          top: '10px',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-end',
          paddingRight: '20px',
        }}
      >
        <div style={{ alignSelf: 'flex-start' }}>
          <SignalWifiStatusbar4BarIcon />
        </div>
      </div>
      <Typography style={{ textAlign: 'center' }}>Desktop Screen Preview</Typography>
      {demoSurvey.questions.length !== 0 && <Survezy demoSurvey={demoSurvey} />}
    </div>
  );
};

export default PreviewScreen;
