import { Box } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 55 , height: 55, ...sx }}>
      {/* <img src={myLogo} alt={'logo'} /> */}
      <svg
        version="1.2"
        baseProfile="tiny"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 288 288"
        overflow="visible"
        xmlSpace="preserve"
      >
        <linearGradient
          id="XMLID_00000119821253979726105110000012291936683286996658_"
          gradientUnits="userSpaceOnUse"
          x1="109.729"
          y1="113.277"
          x2="216.0516"
          y2="157.8409"
        >
          <stop offset="0%" stopColor={PRIMARY_DARK} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <path
          id="XMLID_10_"
          fill="url(#XMLID_00000119821253979726105110000012291936683286996658_)"
          d="M213.3,132.7
	c-8.8-15.6-21.4-24.8-77.3-22.5c-26.3,1.1-37.6-18.9-21.4-32.3c18.3-15.1,46.2-8.8,58.7-1.6c-3.1-6.6-6.8-11.9-11.5-16.6
	c-10.2-10-22.3-15.2-36-13.5c-18,3.7-34.9,13.5-47.5,29.3l0.2,0.2c-16.8,21.9-6,54.1,20.9,61.3l0,0c16.8,4.5,20.7,3.5,38.5,3.3
	c25.7-0.3,30.7,28,8.1,38c-17,7.6-20.4,6.7-33.1-0.5c2.6,6.1,5.9,12.1,10.3,16.2c13.8,12.6,22.4,15.2,36.1,13.5
	c-3.3,0.7-6.6,1.2-10,1.5c28.2-2.3,55.7-19.9,65.1-44.8C217.9,155,219.5,143.6,213.3,132.7z"
        />
        <linearGradient
          id="XMLID_00000081627672342990900720000014377373513010608817_"
          gradientUnits="userSpaceOnUse"
          x1="168.8659"
          y1="65.7838"
          x2="205.7676"
          y2="95.6837"
        >
          <stop offset="0%" stopColor={PRIMARY_LIGHT} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <path
          id="XMLID_9_"
          fill="url(#XMLID_00000081627672342990900720000014377373513010608817_)"
          d="M125.7,46.2
	c13.7-1.7,25.9,3.5,36.1,13.5c8.4,8.3,13.4,18.3,17.6,34c2.9,10.6,12.3,13,17.7,12.7c11.2-0.5,18.8-9.8,16.1-19.9
	C209.1,70.8,173.5,36.2,125.7,46.2z"
        />
        <linearGradient
          id="XMLID_00000090980199204711051900000011873896411169335170_"
          gradientUnits="userSpaceOnUse"
          x1="70.5039"
          y1="90.426"
          x2="213.3228"
          y2="90.426"
        >
          <stop offset="0%" stopColor={PRIMARY_LIGHT} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <path
          id="XMLID_8_"
          fill="url(#XMLID_00000090980199204711051900000011873896411169335170_)"
          d="M213.3,132.7
	c-8.8-15.6-21.4-31.8-77.3-29.5c-26.3,1.1-37.6-11.9-21.4-25.3c18.3-15.1,46.2-8.8,58.7-1.6c-3.1-6.6-6.8-11.9-11.5-16.6
	c-10.2-10-22.3-15.2-36-13.5c-18,3.7-34.9,13.5-47.5,29.3l0.2,0.2c-12,15.7-9.9,36.7,2.2,50c0.6,0.7,18.5,18.7,69.8,2.7
	C200.1,113,211.3,129.4,213.3,132.7z"
        />
        <linearGradient
          id="XMLID_00000023255527697052851670000000913903479264328107_"
          gradientUnits="userSpaceOnUse"
          x1="71.33"
          y1="177.2279"
          x2="159.4163"
          y2="177.2279"
        >
          <stop offset="0%" stopColor={PRIMARY_DARK} />
          <stop offset="100%" stopColor={PRIMARY_MAIN} />
        </linearGradient>
        <path
          id="XMLID_7_"
          fill="url(#XMLID_00000023255527697052851670000000913903479264328107_)"
          d="M135.5,208.9
	c8.2,0.7,16.2,0.1,23.9-1.5c-13.7,1.7-22.3-0.9-36.1-13.5c-11-10.1-15.1-31.8-17.9-37.4c-4.2-8.4-12.5-11.4-17.8-11.2
	c-11.2,0.5-18.4,11.6-15.7,21.7C74.9,178.3,98.1,205.7,135.5,208.9z"
        />
        <g id="XMLID_36_">
          <path
            id="XMLID_00000048496141067704180570000011338624215596908478_"
            fill="#5D5E5E"
            d="M78.4,234.4l1.5-3.3c1.6,1.2,4,2,6.2,2
		c2.6,0,3.6-0.9,3.6-2c0-3.5-11-1.1-11-8.1c0-3.2,2.6-5.9,8-5.9c2.4,0,4.8,0.6,6.6,1.7l-1.3,3.3c-1.8-1-3.6-1.5-5.2-1.5
		c-2.6,0-3.6,1-3.6,2.2c0,3.5,11,1.1,11,8c0,3.2-2.6,5.9-8,5.9C83.1,236.6,80,235.7,78.4,234.4z"
          />
          <path
            id="XMLID_00000088121338800536228660000008853001379662506370_"
            fill="#5D5E5E"
            d="M96.9,228v-10.6h4.4v10.4
		c0,3.6,1.6,5.1,4.2,5.1c2.7,0,4.2-1.5,4.2-5.1v-10.4h4.3V228c0,5.6-3.2,8.6-8.5,8.6S96.9,233.5,96.9,228z"
          />
          <path
            id="XMLID_00000132802036955167565610000007524477801119464374_"
            fill="#5D5E5E"
            d="M130.3,236.3l-3.6-5.3h-0.2h-3.8v5.3h-4.4
		v-18.9h8.2c5,0,8.2,2.6,8.2,6.8c0,2.8-1.4,4.9-3.9,6l4.2,6.1H130.3z M126.2,221h-3.6v6.6h3.6c2.7,0,4-1.2,4-3.3
		C130.2,222.2,128.9,221,126.2,221z"
          />
          <path
            id="XMLID_00000114754178501888325030000013512983199162064301_"
            fill="#5D5E5E"
            d="M156.3,217.4l-8.2,18.9h-4.3l-8.1-18.9
		h4.7l5.7,13.5l5.8-13.5H156.3z"
          />
          <path
            id="XMLID_00000042713856687772016980000003010192757330176130_"
            fill="#5D5E5E"
            d="M172.4,232.8v3.5h-14.6v-18.9h14.3v3.5
		h-9.9v4.1h8.8v3.4h-8.8v4.4H172.4z"
          />
          <path
            id="XMLID_00000022533614385939761000000001829720586089226142_"
            fill="#5D5E5E"
            d="M191.2,232.7v3.6h-16.5v-2.8L185,221
		h-10.1v-3.6h15.9v2.8l-10.3,12.5H191.2z"
          />
          <path
            id="XMLID_00000055689008419673352690000011799610149609578158_"
            fill="#5D5E5E"
            d="M203.1,229.6v6.7h-4.4v-6.7l-7.3-12.1h4.6
		l5,8.4l5-8.4h4.3L203.1,229.6z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
