// @mui
import {
  Link,
  Box,
  Stack,
  Button,
  Card,
  Container,
  Divider,
  Typography,
  useTheme,
  Chip,
  Grid,
  CardHeader,
  Tooltip,
  IconButton,
  Checkbox,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CodeEditor from '@uiw/react-textarea-code-editor';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';
import AndroidIcon from '@mui/icons-material/Android';
import JavascriptIcon from '@mui/icons-material/Javascript';
import { useSnackbar } from 'notistack';
import { useCopyToClipboard } from 'react-use';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import { Icon } from '@iconify/react';
import Avatar from '@mui/material/Avatar';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import Iframe from 'react-iframe';
import { DataGrid } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
// sections
import axios from '../../utils/axios';
import Label from '../../components/Label';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

export default function Containers() {
  const [containers, setContainers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [showInfo, setShowInfo] = useState(false);
  const [videOpen, setVideoOpen] = useState(false);
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up('sm'));
  // const ModalWidth = { match === "true" ? "550px" : "350px"}
  const fetchContainers = () =>
    axios.get('/container/all').then((r) => {
      setShowInfo(r.data.length === 0);
      setContainers(r.data);
      console.log(r.data);
    });
  useEffect(() => {
    fetchContainers();
  }, []);

  const mdUp = useResponsive('up', 'md');
  const { themeStretch } = useSettings();

  return (
    <Page title="Survey Event Types">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          <HeaderBreadcrumbs
            heading="Survey Event Types"
            links={[
              {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
              },
              { name: 'Event Types' },
            ]}
            desc="A Survey Event Type can be created and embedded in your website or mobile application at various user interaction points. Once embedded you can assign or remove survey to an Event Type from your Dashboard with just few clicks."
          />
          {mdUp && (
          //   <Link
          //   underline="none"
          //   target="_blank"
          //   // key={link.name}
          //   rel="noopener"
          //   color="inherit"
          //   variant="body2"
          //   href={`https://www.youtube.com/watch?v=M16DOb1PTS8`}
          // >
            <Tooltip title="Tap to View Demo Video">
              <Button size="large" variant="contained" onClick={() => setVideoOpen(true)}>
                View Demo Video
              </Button>
            </Tooltip>
          // </Link>
          )}
        </Stack>
        {
          <Modal
            open={videOpen}
            onClose={() => {
              setVideoOpen(false);
            }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <div>
            <Iframe
              url="https://www.youtube.com/embed/M16DOb1PTS8"
              // url="https://youtu.be/pUSuDZ2PjTE"
              width={match ? '750px' : '350px'}
              height={match ? '450px' : '300px'}
              id="myId"
              className="myClassname"
              display="initial"
              // position="relative"
            />
            </div>
          </Modal>
        }
        <AddContainer
          containerAdded={() => {
            fetchContainers();
            enqueueSnackbar('Container Added');
          }}
        />

        {containers.length !== 0 && <ContainerTable containers={containers} onNeedRefresh={fetchContainers} />}
        {showInfo && <ContainerInfo />}
      </Container>
    </Page>
  );
}

const ContainerInfo = () => {
  const { themeStretch } = useSettings();

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h5">
          Please refer to the documentation for the Event Type for more information.
          <Link
            sx={{ ml: 1 }}
            target="_blank"
            rel="noopener"
            href={'https://survezy-1.gitbook.io/survezy/documentation/survey-event-type'}
          >
            See Documentation
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

const AddContainer = ({ containerAdded }) => {
  const [open, setOpen] = useState(false);
  const mdUp = useResponsive('up', 'md');
  const matches = useMediaQuery('(min-width:408px)');
  const theme = useTheme();
  const [videOpen, setVideoOpen] = useState(false);
  const match = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <Tooltip title="Tap to create a new event type">
        <Button size="large" variant="contained" onClick={() => setOpen(true)}>
          Create Event Type
        </Button>
      </Tooltip>

      {!mdUp && (
    //    <Link
    //    underline="none"
    //    target="_blank"
    //    // key={link.name}
    //    rel="noopener"
    //    color="inherit"
    //    variant="body2"
    //    href={`https://www.youtube.com/watch?v=M16DOb1PTS8`}
    //  >
       <Tooltip title="Tap to View Demo Video">
         <Button size="large" variant="contained" onClick={() => setVideoOpen(true)} sx={{ mt: !matches ? '1px' : 0, ml: matches ? '1px' : 0, minWidth: '170px' }}>
           View Demo Video
         </Button>
       </Tooltip>
    //  </Link>
      )}
      {
        <Modal
          open={videOpen}
          onClose={() => {
            setVideoOpen(false);
          }}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <div>
            <Iframe
              url="https://www.youtube.com/embed/M16DOb1PTS8"
              width={match ? '750px' : '350px'}
              height={match ? '450px' : '300px'}
              id="myId"
              className="myClassname"
              display="initial"
              // position="relative"
            />
          </div>
        </Modal>
      }
      <Modal open={open} onClose={() => setOpen(false)}>
        <Container component="main" maxWidth="xs">
          <Paper>
            <Box
              sx={{
                marginTop: 12,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '20px 20px',
              }}
            >
              <AddContainerForm
                onContainerAdded={() => {
                  setOpen(false);
                  containerAdded();
                }}
              />
            </Box>
          </Paper>
        </Container>
      </Modal>
    </>
  );
};

const AddContainerForm = ({ onContainerAdded }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    setLoading(true);

    axios
      .post('/container/create', {
        place: data.get('place'),
      })
      .then(onContainerAdded)
      .catch(() => setLoading(false));
  };

  return (
    <>
      <Avatar sx={{ m: 1 }}>
        <Icon icon="akar-icons:shipping-box-02" height="100px" width="100px" />
      </Avatar>
      <Typography component="h1" variant="h5">
        Event Type
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="place"
          // label="Event Type"
          name="place"
          autoFocus
          placeholder="Event Type Name"
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading} sx={{ mt: 3, mb: 2 }}>
          Create
        </LoadingButton>
      </Box>
    </>
  );
};

function ContainerTable({ containers, onNeedRefresh }) {
  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="Existing Survey Event Types" sx={{ mb: 3, textAlign: 'center' }} />
      <Scrollbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Event Type</TableCell>
                <TableCell>Active Survey</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {containers.map((container, index) => (
                <TableRow key={index}>
                  <TableCell>{container.place}</TableCell>
                  <TableCell>{container?.surveyName ?? <EmptyContainer />}</TableCell>
                  <TableCell align="right">
                    <ContainerActions
                      place={container.place}
                      containers={containers}
                      link={container.link}
                      onNeedRefresh={onNeedRefresh}
                      empty={container.surveyId === null}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

function EmptyContainer() {
  const theme = useTheme();

  return (
    <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={'warning'}>
      {'Empty'}
    </Label>
  );
}

function ContainerActions({ place, containers, link, empty, onNeedRefresh }) {
  const [open, setOpen] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [state, copyToClipboard] = useCopyToClipboard();

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleReplace = () => {
    setShow(true);
    handleClose();
  };

  const copyToClipBoard = (text) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to Clipboard');
    handleClose();
  };

  const emptyContainer = () => {
    handleClose();
    enqueueSnackbar('Emptying the container');

    axios.post('/container/empty', { place }).then(() => {
      onNeedRefresh();
      enqueueSnackbar('Container Empty');
    });
  };

  const deleteContainer = () => {
    handleClose();
    enqueueSnackbar('Deleting container');

    axios.post('/container/delete', { place }).then(() => {
      onNeedRefresh();
      enqueueSnackbar('Container deleted');
    });
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <Stack direction="row" align="right" spacing={1}>
        <Tooltip title="Replace your existing survey ">
          <Chip
            icon={<Iconify icon={'tabler:replace'} sx={{ ...ICON }} />}
            label={empty ? 'Assign Survey' : 'Replace Survey'}
            onClick={handleReplace}
          />
        </Tooltip>
        <Tooltip title="Copy event Id to your clipboard">
          <Chip
            icon={<Iconify icon={'akar-icons:copy'} sx={{ ...ICON }} />}
            label="Copy Event ID"
            onClick={() => copyToClipBoard(link)}
          />
        </Tooltip>
        <Tooltip title="Get Code">
          <Chip
            icon={<Iconify icon={'bx:code-alt'} sx={{ ...ICON }} />}
            label="Get Code"
            onClick={() => setShowCode(true)}
          />
        </Tooltip>
        {!empty && (
          <Tooltip title="Remove survey from your event ">
            <Chip
              icon={<Iconify icon={'ep:remove'} sx={{ ...ICON }} />}
              label="Remove Survey"
              onClick={emptyContainer}
            />
          </Tooltip>
        )}
        <Tooltip title="Delete event ">
          <Chip
            color="error"
            icon={<Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />}
            label="Delete"
            onClick={deleteContainer}
          />
        </Tooltip>
      </Stack>

      {show && (
        <ShowSurvey
          place={place}
          containers={containers}
          onClose={() => setShow(false)}
          onReplaced={() => {
            onNeedRefresh();
            setShow(false);
            enqueueSnackbar('Survey added');
          }}
        />
      )}
      {showCode && <CodeCopy link={link} onClose={() => setShowCode(false)} />}
    </>
  );
}

const ShowSurvey = ({ place, containers, onClose, onReplaced }) => {
  const [survey, setSurvey] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [showEmptyToast, setShowEmptyToast] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const surveyMapper = (containers, survey) => ({
    ...survey,
    container:
      containers.find((container) => container.surveyId === survey.id)?.place ?? 'Not attached to any event type',
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get('/survey/info')
      .then((r) => {
       
        setSurvey(r.data.reverse().map((survey, index) => ({ id: index + 1, startedOn:new Date(`${survey.startOn.split("T")[0]},${survey.startOn.split("T")[1].split(".")[0] }`).toLocaleDateString(), ...surveyMapper(containers, survey ) })));
      })
      .finally(() => setLoading(false));
  }, [containers]);

  const columns = [
    {
      field: 'id',
      headerName: 'Survey Id',
      width: 100,
    },
    {
      field: 'startedOn',
      headerName: 'Created On',
      width: 150,
    },
    {
      field: 'name',
      headerName: 'Survey Name',
      width: 300,
    },
    {
      field: 'container',
      headerName: 'Active Event Type',
      width: 350,
    },
    
  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: matches?'85%':'auto',
    border: 2,
    p: 4,
  };

  const handleCellClick = (id) => {
    setLoading(true);

    axios
      .post('/container/replace', {
        place,
        surveyId: id.row.id,
        surveyName: id.row.name,
      })
      .then(() => onReplaced())
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <div>
      <Modal open onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Card sx={style}>
          <div style={{ height: 400, width: '100%' }}>
            {survey.length !== 0 && (
              <Typography color="secondary" >
                Once you have attached the survey to the event type, it will start appearing at the location of the event type in your code
              </Typography>
            )}
            <DataGrid
              rows={survey}
              columns={columns}
              pageSize={5}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <Typography color="black">
                      No survey found
                      <Link pl={1} component={RouterLink} to={PATH_DASHBOARD.kanban}>
                        Create One
                      </Link>
                    </Typography>
                  </Stack>
                ),
              }}
              loading={loading}
              onCellClick={(id) => handleCellClick(id)}
            />
          </div>
        </Card>
      </Modal>
    </div>
  );
};

const CodeCopy = ({ open, onClose, link }) => {
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [state, copyToClipboard] = useCopyToClipboard();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 2,
    p: 4,
  };
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const copyToClipBoard = (text) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to Clipboard');
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };
  const reactnpm = `npm install survezy_widget `;
  const [reactCode, setReactCode] = React.useState(
    `import React, { Component } from 'react';\nimport { Survezy } from 'survezy_widget'; \nimport 'survezy_widget/dist/index.css';  \nclass Example extends Component { \nrender() { \n<Survezy eventId="${link}" darkMode /> \n}\n}\n`
  );
  const jscode1 = `<script src="https://survezyapi.wimwisure.com/survezy/1.0.25/survezy.js"></script>`;
  const jscode2 = `<script> survezy.Show('${link}'); </script>`;
  const androidCode1 = `allprojects {\nrepositories {\n...\nmaven { url 'https://jitpack.io' }\n}\n}`;
  const androidCode2 = `dependencyResolutionManagement {\n...\nrepositories {\n...\nmaven{url 'https://jitpack.io'}\n}\n}`;
  return (
    <Modal open onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Card sx={style}>
        <div style={{ height: 500, width: '100%', overflowY: 'scroll' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon label tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab icon={<Iconify icon={'logos:react'} />} label="React" />
            <Tab icon={<Iconify icon={'logos:javascript'} />} label="Javascript" />
            <Tab icon={<Iconify icon={'cib:android'} color="#3DDC84" />} label="Android" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Typography variant="code" sx={{ display: 'block', fontWeight: 'bold' }}>
              <Checkbox {...label} disabled checked />
              {`Step 1 - Install our widget`}
            </Typography>

            <div style={{ position: 'relative' }}>
              <CodeEditor
                value={reactnpm}
                language="js"
                // placeholder="Please enter JS code."
                // onChange={(evn) => setCode(code)}
                disabled
                padding={15}
                style={{
                  fontSize: 14,
                  backgroundColor: '#000',
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
              <Tooltip title="Copy Code">
                <IconButton
                  aria-label="copy"
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => copyToClipBoard(reactnpm)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Typography variant="code" sx={{ display: 'block', fontWeight: 'bold' }}>
              <Checkbox {...label} disabled checked />
              {`Step 2 - Run this code`}
            </Typography>

            <div style={{ position: 'relative' }}>
              <CodeEditor
                value={reactCode}
                language="js"
                // placeholder="Please enter JS code."
                // onChange={(evn) => setCode(code)}
                disabled
                padding={15}
                style={{
                  fontSize: 14,
                  backgroundColor: '#000',
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
              <Tooltip title="Copy Code">
                <IconButton
                  aria-label="copy"
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => copyToClipBoard(reactCode)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Typography variant="code" sx={{ display: 'block', fontWeight: 'bold' }}>
              <Checkbox {...label} disabled checked />
              {`Step 1 - Embed our script`}
            </Typography>
            <div style={{ position: 'relative' }}>
              <CodeEditor
                value={jscode1}
                language="js"
                // placeholder="Please enter JS code."
                // onChange={(evn) => setReactCode(reactCode)}
                padding={15}
                disabled
                style={{
                  fontSize: 14,
                  backgroundColor: '#000',
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
              <Tooltip title="Copy Code">
                <IconButton
                  aria-label="copy"
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => copyToClipBoard(jscode1)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Typography variant="code" sx={{ display: 'block', fontWeight: 'bold' }}>
              <Checkbox {...label} disabled checked />
              {`Step 2 - Show Survey`}
            </Typography>
            <div style={{ position: 'relative' }}>
              <CodeEditor
                value={jscode2}
                language="js"
                // placeholder="Please enter JS code."
                // onChange={(evn) => setReactCode(reactCode)}
                disabled
                padding={15}
                style={{
                  fontSize: 14,
                  backgroundColor: '#000',
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
              <Tooltip title="Copy Code">
                <IconButton
                  aria-label="copy"
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => copyToClipBoard(jscode2)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Typography
              variant="code"
              sx={{ display: 'block', fontWeight: 'bold', marginTop: '8px', marginBottom: '8px' }}
            >
              <Checkbox {...label} disabled checked />
              {`Step 1 - Install via Gradle`}
            </Typography>
            <div style={{ position: 'relative' }}>
              <CodeEditor
                value={androidCode1}
                language="js"
                // placeholder="Please enter JS code."
                // onChange={(evn) => setReactCode(reactcode)}
                padding={15}
                disabled
                style={{
                  fontSize: 14,
                  backgroundColor: '#000',
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
              <Tooltip title="Copy Code">
                <IconButton
                  aria-label="copy"
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => copyToClipBoard(androidCode1)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
            <Typography
              variant="code"
              sx={{ display: 'block', fontWeight: 'bold', marginTop: '8px', marginBottom: '8px' }}
            >
              <Checkbox {...label} disabled checked color="error" />
              {`Note: If you have a Gradle version higher than 6.5, add the following code to the settings Gradle.`}
            </Typography>
            <div style={{ position: 'relative' }}>
              <CodeEditor
                value={androidCode2}
                language="js"
                // placeholder="Please enter JS code."
                // onChange={(evn) => setCode(code)}
                padding={15}
                disabled
                style={{
                  fontSize: 14,
                  backgroundColor: '#000',
                  fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
              />
              <Tooltip title="Copy Code">
                <IconButton
                  aria-label="copy"
                  style={{ position: 'absolute', right: '10px', top: '5px' }}
                  onClick={() => copyToClipBoard(androidCode2)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          </TabPanel>
        </div>
      </Card>
    </Modal>
  );
};
