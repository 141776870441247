import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { Link, IconButton } from '@mui/material';
import { Icon } from '@iconify/react';

const docLink = new Map([
  ['Event Types', 'survey-event-type/'],
  ['Create Survey', 'create-survey/'],
  ['Survey', 'create-survey/'],
])

const ViewDocumentation = ({ heading }) => {
  if (!docLink.has(heading)) return null

  return (
    <Tooltip title="View Documentation">
      <Link
        color="primary"
        underline="hover"
        target="_blank"
        rel="noopener"
        href={`https://survezy-1.gitbook.io/survezy/documentation/${docLink.get(heading)}`}
      >
        <IconButton variant='outlined' sx={{ marginBottom: '4px' }}>
          <Icon icon="clarity:help-info-solid" color="#1072b3" />
        </IconButton>
      </Link>
    </Tooltip>
  )
}

export default ViewDocumentation;
