import isString from 'lodash/isString';
import PropTypes from 'prop-types';
// @mui
import InfoIcon from '@mui/icons-material/Info';
import { capitalCase } from 'change-case';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { useSnackbar } from 'notistack';

import Paper from '@mui/material/Paper';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Box,
  Typography,
  Link,
  IconButton,
  Button,
  OutlinedInput,
  InputAdornment,
  ClickAwayListener,
  Container,
} from '@mui/material';
//
import { useEffect, useRef, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ViewDocumentation from '../pages/dashboard/ViewDocumentation';
import Breadcrumbs from './Breadcrumbs';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  inputHeading,
  surveyNameError,
  onChange,
  desc,
  setSurveyNameError,
  totalResponses,
  moreLink = '' || [],
  sx,
  ...other
}) {
  const surveyNameRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState(inputHeading);
  // useEffect(() => {
  //   if (!value || value === 'Untitled' || surveyNameError) surveyNameRef.current?.focus();
  // }, [value, surveyNameRef, surveyNameError])

  function checkforLength(text) {
    if (text?.length >= 90) {
      setSurveyNameError(true);
      enqueueSnackbar("Survey name can't be greater than 90 characters", {
        variant: 'error',
      });
    } else {
      setSurveyNameError(false);
    }
  }
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {heading}
            <ViewDocumentation heading={heading} />
          </Typography>
          {desc === undefined && <Breadcrumbs links={links} {...other} />}
          {desc && <Typography sx={{ flexShrink: 0, maxWidth: '900px', fontSize: '15px' }}>{desc}</Typography>}
        </Box>
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>
      <Box sx={{mt: 2}}>
        {inputHeading !== undefined && (
          <ClickAwayListener
            onClickAway={() => {
              if (!value || value === 'Untitled') {
                setSurveyNameError(true);
              }
              if (inputHeading !== value) {
                onChange(capitalCase(value));
              }
              return null;
            }}
          >
            <OutlinedInput
              inputProps={{ style: { textTransform: 'capitalize' } }}
              size="small"
              placeholder="Survey Name"
              value={value}
              inputRef={surveyNameRef}
              onChange={(e) => {
                checkforLength(e.target.value);
                if (surveyNameError) {
                  setSurveyNameError(false);
                }
                setValue(e.target.value);
              }}
              error={value.length >= 90 || surveyNameError}
              sx={{
                typography: 'h6',
                // '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Tooltip title="Click to Edit Survey Name">
                    <ModeEditIcon onClick={() => surveyNameRef.current?.focus()} />
                  </Tooltip>
                </InputAdornment>
              }
            />
          </ClickAwayListener>
        ) }
      </Box>
      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
       {totalResponses && <Typography>
          Total Responses: {totalResponses}
        </Typography>}
      </Box>
    </Box>
  );
}
