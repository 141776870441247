import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';

// ----------------------------------------------------------------------

const LINKS = [
  // {
  //   headline: 'Survezy',
  //   children: [
  //     // { name: 'About us', href: PATH_PAGE.about },
  //     // { name: 'Contact us', href: PATH_PAGE.contact },
  //     // { name: 'FAQs', href: PATH_PAGE.faqs },
  //   ],
  // },
  {
    headline: 'Product',
    children: [
      { name: 'Pricing', href: '/pricing' },
      { name: 'Documentation', href: 'https://survezy-1.gitbook.io/survezy/documentation' },
      // { name: 'New Delhi, 359 TG Road', href: '#' },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '/terms_and_conditions' },
      { name: 'Privacy Policy', href: '/privacy_policy' },
    ],
  },

  {
    headline: 'Contact',
    children: [
      { name: 'info@survezy.app', href: '#' },
      // { name: 'New Delhi, 359 TG Road', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const doc = "Documentation"
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 8, mb: 5,}}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-around' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          {/* <Grid item xs={12} sx={{ mb: 0 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' }, marginLeft:'-10px' }} />
          </Grid>  */}
          <Grid item xs={8} md={3} sx={{marginTop: '-10px'}}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' }, marginLeft: '-10px' }} />

            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
            Survezy is an in-app survey tool with enhanced user experience to collect survey responses from your users.
            </Typography>

            {/* <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack> */}
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-around" sx={{ pb: 5 }}>
              {/* <Typography
                component="p"
                variant="body2"
                sx={{
                  mt: 0,
                  pb: 5,
                  fontSize: 13,
                  textAlign: { xs: 'center', md: 'center' },
                  
                }}
              >
                © 2022. All rights reserved !!!
              </Typography> */}
              {/* <Logo sx={{ mx: { xs: 'auto', md: 'inherit' }, mt: 0 }} /> */}
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => {
                    
                    if (link.name.toUpperCase() === doc.toUpperCase()) {
                      return (
                        <Link
                          underline="hover"
                          target="_blank"
                          key={link.name}
                          rel="noopener"
                          color="inherit"
                          variant="body2"
                          href={`https://survezy-1.gitbook.io/survezy/documentation/`}
                        >
                          {link.name}
                        </Link>
                      );
                    }
                    return (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    );
                  })}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
